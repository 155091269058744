import {createSelector} from "reselect"
import {Map, List} from "immutable"

import {getState} from "../reducers/user"

export const getUserData = createSelector(getState, user => user)

export const getProfileData = createSelector(getUserData, user => user.get('profile') || Map())

export const getUserScopes = createSelector(getUserData, user => {
    if (!user || !user.size || !user.has('scopes')) {
        return List()
    }

    return user.get('scopes')
})

export const isAdmin = createSelector(getUserScopes, scopes => scopes.indexOf('admin') !== -1)

export const isTrial = createSelector(getUserScopes, scopes => scopes.indexOf('trial') !== -1)

export const isSuperAdmin = createSelector(getUserScopes, scopes => scopes.indexOf('super-admin') !== -1)

export const getUserSettings = createSelector(getUserData, user => user.get('settings') || Map())

export const getUserRoles = createSelector(getUserData, user => user.get('roles') || List())

export const currentUserHasRole = (role) => createSelector(getUserRoles, roles => roles.indexOf(role) !== -1)
