import React, {Component} from "react"
import PropTypes from "prop-types"
import StorageService from "../../../services/StorageServices"
import {getAppURL} from "../../../helpers/CommonHelper"
import Loading from "../../../shared-components/Loading"

class OauthCallback extends Component {
    componentDidMount() {
        const {history, oauthCallback} = this.props

        oauthCallback(history)
            .then(() => {
                StorageService.remove('redirectTo')

                // const scopes = StorageService.getUserData().scopes

                // // if(scopes && scopes.indexOf('trial') !== -1){
                // //     return history.push('/a/trial')
                // // }
                const redirectTo = '/a'

                return history.push(redirectTo)
            })
    }

    render() {
        return (
            <div className="oauth-callback">
                <Loading/>
            </div>
        )
    }
}

OauthCallback.propTypes = {
    user: PropTypes.object.isRequired,
    oauthCallback: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
}

export default OauthCallback
