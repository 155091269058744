import {createReducer} from "redux-create-reducer"
import {fromJS} from "immutable"
import {TOGGLE_COLLAPSE_SIDEBAR} from "./actionTypes"

const initRootState = {
    openSidebar: false
}

export default createReducer(fromJS(initRootState), {
    [TOGGLE_COLLAPSE_SIDEBAR](state, action) {
        const current = state.get('openSidebar')

        return state.set('openSidebar', !current)
    }
})
