export const AUTH_LOGOUT = "AUTH_LOGOUT"
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS"
export const AUTH_CHECK_STARTUP = "AUTH_CHECK_STARTUP"
export const AUTH_FAILURE = "AUTH_FAILURE"

export const AUTH_CHANGE_ACCESS_TOKEN = "AUTH_CHANGE_ACCESS_TOKEN"

export const SAVE_USER_SETTINGS_REQUEST = "SAVE_USER_SETTINGS_REQUEST"
export const SAVE_USER_SETTINGS_SUCCESS = "SAVE_USER_SETTINGS_SUCCESS"
export const SAVE_USER_SETTINGS_FAILURE = "SAVE_USER_SETTINGS_FAILURE"

export const HEART_BEAT_REQUEST = "HEART_BEAT_REQUEST"
export const HEART_BEAT_SUCCESS = "HEART_BEAT_SUCCESS"
export const HEART_BEAT_FAILURE = "HEART_BEAT_FAILURE"

export const FETCH_CURRENT_APP_DATA = "FETCH_CURRENT_APP_DATA"

export const FETCH_APP_DATA_REQUEST = "FETCH_APP_DATA_REQUEST"
export const FETCH_APP_DATA_SUCCESS = "FETCH_APP_DATA_SUCCESS"
export const FETCH_APP_DATA_FAILURE = "FETCH_APP_DATA_FAILURE"

export const FETCH_FORCE_REFRESH_REQUEST = "FETCH_FORCE_REFRESH_REQUEST"
export const FETCH_FORCE_REFRESH_SUCCESS = "FETCH_FORCE_REFRESH_SUCCESS"
export const FETCH_FORCE_REFRESH_FAILURE = "FETCH_FORCE_REFRESH_FAILURE"
