import React, {Component} from "react"
import {Container} from "reactstrap"
import classNames from "classnames"
import PropTypes from "prop-types"
import {Redirect} from "react-router-dom"
import {FormattedMessage} from "react-intl"
import withViewport from "../../../shared-components/withViewport"
import Loading from "../../../shared-components/Loading"
import GoogleLogin from 'react-google-button'
import { _updateAffiliate } from "../../../services/UserService";
import queryString from 'query-string';
import appConfig from '../../../helpers/common/getEnv'

class LoginPage extends Component {

    _handleGoogleLogin = () => {
        const params = queryString.parse(window.location.search)
        window.location.href = `${appConfig('baseAPIUrl')}/auth/google${params.code 
            ? `?af=${encodeURIComponent(JSON.stringify({af: params.code}))}` 
            : ''
        }`
    }

    render() {
        const {props} = this
        const {isAuthenticated, message, loading} = props

        if (isAuthenticated) {
            const params = queryString.parse(window.location.search)
            if (params.code) {
                _updateAffiliate(params.code)
                    .then(_result => { })
                    .catch(err => { console.error(err.message) })
            }

            return <Redirect push to="/" />
        }

        return (
            <Container className={classNames("LoginPage", {noError: !message})}>

                <div className="Wrapper">
                    <div className="loginTool">
                        <Loading loading={loading}/>

                        <h1 className="Header"><FormattedMessage id="signIn.title"/></h1>
                        <div className="LoginMethod">
                            {
                                !!message &&
                                <div className="Error text-center">{message}</div>
                            }

                            <p className="Description"><FormattedMessage id="signIn.description"/></p>

                            <div className="WrapperBtnSocial">
                                <GoogleLogin onClick={this._handleGoogleLogin} />
                            </div>
                        </div>
                    </div>

                    <div className="WrapperOtherTools">
                        <a href="https://app.spybadao.com" target="_blank" className="tool-item spybadass" rel="noopener noreferrer">
                            <img src="/assets/images/spyads.png" alt="link to spybadao"/>
                        </a>

                        <a href="https://merch.spyamz.com" target="_blank" className="tool-item spyamz" rel="noopener noreferrer">
                            <img src="/assets/images/spyamz.png" alt="link to spyamz"/>
                        </a>
                    </div>
                </div>
            </Container>
        )
    }
}

LoginPage.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    socialLogin: PropTypes.func.isRequired
}

export default withViewport(LoginPage)
