
export const FETCH_USER_BILL_HISTORY_REQUEST = "FETCH_USER_BILL_HISTORY_REQUEST"
export const FETCH_USER_BILL_HISTORY_SUCCESS = "FETCH_USER_BILL_HISTORY_SUCCESS"
export const FETCH_USER_BILL_HISTORY_FAILURE = "FETCH_USER_BILL_HISTORY_FAILURE"

export const FETCH_USER_MEMBERSHIP_REQUEST = "FETCH_USER_MEMBERSHIP_REQUEST"
export const FETCH_USER_MEMBERSHIP_SUCCESS = "FETCH_USER_MEMBERSHIP_SUCCESS"
export const FETCH_USER_MEMBERSHIP_FAILURE = "FETCH_USER_MEMBERSHIP_FAILURE"

export const CANCEL_CURRENT_MEMBERSHIP_REQUEST = "CANCEL_CURRENT_MEMBERSHIP_REQUEST"
export const CANCEL_CURRENT_MEMBERSHIP_SUCCESS = "CANCEL_CURRENT_MEMBERSHIP_SUCCESS"
export const CANCEL_CURRENT_MEMBERSHIP_FAILURE = "CANCEL_CURRENT_MEMBERSHIP_FAILURE"
