export const FETCH_LIST_TREND_ITEMS_REQUEST = "trends/FETCH_LIST_TREND_ITEMS_REQUEST"
export const FETCH_LIST_TREND_ITEMS_SUCCESS = "trends/FETCH_LIST_TREND_ITEMS_SUCCESS"
export const FETCH_LIST_TREND_ITEMS_FAILURE = "trends/FETCH_LIST_TREND_ITEMS_FAILURE"

export const FETCH_ITEM_DETAIL_REQUEST = "trends/FETCH_ITEM_DETAIL_REQUEST"
export const FETCH_ITEM_DETAIL_SUCCESS = "trends/FETCH_ITEM_DETAIL_SUCCESS"
export const FETCH_ITEM_DETAIL_FAILURE = "trends/FETCH_ITEM_DETAIL_FAILURE"

export const FETCH_TOTAL_FAVORITE_ITEMS_REQUEST = "trends/FETCH_TOTAL_FAVORITE_ITEMS_REQUEST"
export const FETCH_TOTAL_FAVORITE_ITEMS_SUCCESS = "trends/FETCH_TOTAL_FAVORITE_ITEMS_SUCCESS"
export const FETCH_TOTAL_FAVORITE_ITEMS_FAILURE = "trends/FETCH_TOTAL_FAVORITE_ITEMS_FAILURE"

export const TOGGLE_LIKE_ITEM_REQUEST = "trends/TOGGLE_LIKE_ITEM_REQUEST"
export const TOGGLE_LIKE_ITEM_SUCCESS = "trends/TOGGLE_LIKE_ITEM_SUCCESS"
export const TOGGLE_LIKE_ITEM_FAILURE = "trends/TOGGLE_LIKE_ITEM_FAILURE"

export const CHANGE_PAGINATION_ITEMS_CURRENT_PAGE = "trends/CHANGE_PAGINATION_ITEMS_CURRENT_PAGE"
export const CHANGE_PAGINATION_ITEMS_PER_PAGE = "trends/CHANGE_PAGINATION_ITEMS_PER_PAGE"
export const CHANGE_SEARCH_ITEMS_QUERY = "trends/CHANGE_SEARCH_ITEMS_QUERY"
export const TOGGLE_SELECT_ALIVE_ITEMS = "trends/TOGGLE_SELECT_ALIVE_ITEMS"

export const SHOW_ITEM_DETAIL = "trends/SHOW_ITEM_DETAIL"
export const CLOSE_MODAL_ITEM_DETAIL = "trends/CLOSE_MODAL_ITEM_DETAIL"

export const TOGGLE_EDITOR_FILTER_RANK = "trends/TOGGLE_EDITOR_FILTER_RANK"
export const OPEN_EDITOR_FILTER_RANK = "trends/OPEN_EDITOR_FILTER_RANK"
export const CLOSE_EDITOR_FILTER_RANK = "trends/CLOSE_EDITOR_FILTER_RANK"
export const CHANGE_TEMP_FILTER_RANK = "trends/CHANGE_TEMP_FILTER_RANK"
export const SUBMIT_FILTER_RANK = "trends/SUBMIT_FILTER_RANK"
