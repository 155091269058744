import React, {Component} from "react"
import {Redirect} from "react-router-dom"
import PropTypes from "prop-types"

class HomePage extends Component {
    render() {
        const {isAuthenticated} = this.props

        if (isAuthenticated) {
            return <Redirect to='/a'/>
        }

        return <Redirect to='/login'/>
    }
}

HomePage.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
}

export default HomePage
