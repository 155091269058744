import {createReducer} from "redux-create-reducer"
import {combineReducers} from "redux-immutable"
import {fromJS, List, Map} from "immutable"

import {
    TRAIL_FETCH_LIST_ITEMS_REQUEST,
    TRAIL_FETCH_LIST_ITEMS_FAILURE,
    TRAIL_FETCH_LIST_ITEMS_SUCCESS,
    TRAIL_FETCH_ITEM_DETAIL_REQUEST,
    TRAIL_FETCH_ITEM_DETAIL_SUCCESS,
    TRAIL_FETCH_ITEM_DETAIL_FAILURE,
    TRIAL_TOGGLE_LIKE_ITEM_SUCCESS,
    TRIAL_INIT_SETUP_META,
    TRIAL_CHANGE_PAGINATION_ITEMS_CURRENT_PAGE,
    TRIAL_CHANGE_PAGINATION_ITEMS_PER_PAGE,
    TRIAL_SHOW_ITEM_DETAIL,
    TRIAL_CLOSE_MODAL_ITEM_DETAIL,
    TRIAL_CHANGE_QUERY,
    TRIAL_SWITCH_LAYOUT_PRODUCTS,
    TRAIL_FETCH_TOTAL_ITEMS_SUCCESS,
    TRIAL_CHANGE_SEARCH_ITEMS_QUERY,
    TRIAL_CHANGE_SELECT_STATUS_ITEMS,
    TRIAL_TOGGLE_EDITOR_FILTER_RANK,
    TRIAL_OPEN_EDITOR_FILTER_RANK,
    TRIAL_CLOSE_EDITOR_FILTER_RANK,
    TRIAL_CHANGE_TEMP_FILTER_RANK,
    TRIAL_SUBMIT_FILTER_REVIEW,
    TRIAL_SUBMIT_FILTER_SALE,
    TRIAL_SUBMIT_FILTER_VIEW,
    TRIAL_SUBMIT_FILTER_RANK,
    TRIAL_OPEN_EDITOR_FILTER_TIME_AVAILABLE,
    TRIAL_CLOSE_EDITOR_FILTER_TIME_AVAILABLE,
    TRIAL_TOGGLE_EDITOR_FILTER_TIME_AVAILABLE,
    TRIAL_SUBMIT_FILTER_TIME_AVAILABLE,
    TRIAL_CHANGE_TEMP_FILTER_TIME_AVAILABLE,
    TRIAL_CHANGE_FIELD_NAME_SORT_BY,
    TRIAL_CHANGE_SELECT_TYPE_ITEM,
    TRIAL_CHANGE_SELECT_BRAND_TYPE_ITEM,
    TRIAL_TOGGLE_EDITOR_FILTER_PRICE,
    TRIAL_OPEN_EDITOR_FILTER_PRICE,
    TRIAL_CLOSE_EDITOR_FILTER_PRICE,
    TRIAL_CHANGE_TEMP_FILTER_PRICE,
    TRIAL_SUBMIT_FILTER_PRICE,
    TRIAL_SAVE_QUERY_SEARCH_ITEM_REQUEST,
    TRIAL_SAVE_QUERY_SEARCH_ITEM_SUCCESS,
    TRIAL_SAVE_QUERY_SEARCH_ITEM_FAILURE,
    TRIAL_SET_CURRENT_MY_ANALYTIC_ID,
    TRIAL_SET_HIDDEN_FUZZY_POST
} from "./actionTypes"
import {AUTH_LOGIN_SUCCESS} from "../../constants/ActionTypes"
import {FETCH_REMOVE_MY_ANALYTIC_SUCCESS} from "../my-analytics/actionTypes"
import getCookie from "../../helpers/cookie/getCookie";

const initItemsState = {
    byIds: {},
    allIds: [],
    loading: false,
    error: ''
}

const items = createReducer(fromJS(initItemsState), {
    [TRAIL_FETCH_LIST_ITEMS_REQUEST](state) {
        return state
            .set('loading', true)
            .set('error', '')
    },
    [TRAIL_FETCH_LIST_ITEMS_FAILURE](state, action) {
        const {error} = action
        const message = error.message || error

        return state
            .set('loading', false)
            .set('error', message)
            .set('allIds', fromJS([]))
            .set('byIds', fromJS({}))
    },
    [TRAIL_FETCH_LIST_ITEMS_SUCCESS](state, action) {
        const {result, merge} = action
        const {data, success} = result

        if (!success) {
            return state.set('loading', false)
        }

        const items = data.docs || []

        let byIds = Map()
        let allIds = List()
        items.forEach((item) => {
            byIds = byIds.set(item._id, fromJS(item))
            allIds = allIds.push(item._id)
        })

        return state
            .set('loading', false)
            .set('byIds', !!merge ? state.get('byIds').merge(byIds) : byIds)
            .set('allIds', !!merge ? state.get('allIds').concat(allIds) : allIds)
            .set('error', '')
    },
    [TRAIL_FETCH_ITEM_DETAIL_REQUEST](state, action) {
        const {productId} = action

        return state.setIn(['byIds', productId, 'loading'], true)
    },
    [TRAIL_FETCH_ITEM_DETAIL_SUCCESS](state, action) {
        const {result} = action
        const {data, success} = result

        if (!success) {
            return state
        }

        const {_id} = data

        return state.setIn(['byIds', _id], fromJS({
            ...data,
            loading: false
        }))
    },
    [TRAIL_FETCH_ITEM_DETAIL_FAILURE](state, action) {
        const {productId} = action

        return state.setIn(['byIds', productId, 'loading'], false)
    },
    [TRIAL_TOGGLE_LIKE_ITEM_SUCCESS](state, action) {
        const {result} = action
        const {success, data} = result

        if (!success) {
            return state
        }

        const {id, liked} = data

        return state.setIn(['byIds', id, 'liked'], liked)
    }
})

const pagination = createReducer(
    Map({
        page: 1,
        totalPage: 0,
        perPage: 50,
        total: 0,
        query: {}
    }),
    {
        [TRIAL_INIT_SETUP_META](state, action) {
            return state.set('page', 1)
        },
        [TRAIL_FETCH_LIST_ITEMS_SUCCESS](state, action) {
            const {result} = action
            const {data, success} = result

            if (!success) {
                return state
            }

            return state
                .set('totalPage', data.pages)
                .set('total', data.total)
        },
        [TRIAL_CHANGE_PAGINATION_ITEMS_CURRENT_PAGE](state, action) {
            const {page} = action

            return state.set('page', page)
        },

        [TRIAL_CHANGE_PAGINATION_ITEMS_PER_PAGE](state, action) {
            const {number} = action

            return state.set('perPage', number)
        },
        [AUTH_LOGIN_SUCCESS](state, action) {
            const {data} = action
            const {profile} = data
            const {settings} = profile

            if (settings) {
                const {showItemsPerPage} = settings

                if (showItemsPerPage) {
                    return state.set('perPage', showItemsPerPage)
                }
            }

            return state
        }
    }
)

const showItem = createReducer(fromJS({
    isOpen: false,
    id: '',
    index: 0,
}), {
    [TRIAL_SHOW_ITEM_DETAIL](state, action) {
        const {data} = action
        const {id, index} = data

        return state
            .set('isOpen', true)
            .set('id', id)
            .set('index', index)
    },
    [TRIAL_CLOSE_MODAL_ITEM_DETAIL](state, action) {
        return state.set('isOpen', false)
    }
})

const initMetaState = {
    total: 0,
    query: {
        term: '',
        excludedKeyword: '',
        searchType: 'all_words'
    },
    filter: {},
    selectStatus: 'alive',
    rank: {
        current: {
            from: '',
            to: ''
        },
        temp: {
            from: 1,
            to: ''
        },
        isOpen: false
    },
    price: {
        current: {
            from: 0,
            to: 0
        },
        temp: {
            from: 0,
            to: 0
        },
        isOpen: false
    },
    available: {
        isOpen: false,
        current: {
            from: '',
            to: null
        },
        temp: {
            from: null,
            to: null
        }
    },
    review: {
        from: '',
        to: ''
    },
    sale: {
        from: '',
        to: ''
    },
    view: {
        from: '',
        to: ''
    },
    sortBy: {
        field: 'trending',
    },
    type: 'all@POD',
    brandType: 'all',
    layout: 'grid',
    hiddenFuzzyPost: getCookie('isHiddenFuzzyPosts') || false
}

const meta = createReducer(fromJS(initMetaState), {
    [TRIAL_INIT_SETUP_META](state) {
        const init = fromJS(initMetaState)

        return state.merge(init.remove('layout'))
    },
    [TRIAL_CHANGE_QUERY](state, action) {
        const {data} = action
        const {search, filter, sort} = data

        const rank = filter.rank || {}
        const price = filter.price || {}
        const timeAvailable = filter.timeAvailable || {}

        return state
            .set('query', fromJS({
                term: search.term || '',
                excludedKeyword: search.excluded || '',
                searchType: search.type || 'all_words'
            }))
            .set('selectStatus', filter.status || 'alive')
            .set('type', filter.type || 'all')
            .setIn(['rank', 'current'], fromJS({
                from: rank.from,
                to: rank.to
            }))
            .setIn(['available', 'current'], fromJS({
                from: timeAvailable.from,
                to: timeAvailable.to
            }))
            .setIn(['sortBy', 'field'], sort.field || 'trending')
            .setIn(['price', 'current'], fromJS({
                from: price.from || 0,
                to: price.to || 0
            }))
            .set('brandType', filter.brandType || 'all')
    },
    [TRIAL_SWITCH_LAYOUT_PRODUCTS](state, action) {
        const {layout} = action

        return state.set('layout', layout)
    },
    [AUTH_LOGIN_SUCCESS](state, action) {
        const {data} = action
        const {profile} = data
        const {settings} = profile

        if (settings) {
            const {layoutListing} = settings

            if (layoutListing) {
                return state.set('layout', layoutListing)
            }
        }

        return state
    },
    [TRAIL_FETCH_TOTAL_ITEMS_SUCCESS](state, action) {
        const {result} = action
        const {success, data} = result

        if (!success) {
            return state
        }

        return state.set('total', data)
    },
    [TRIAL_CHANGE_SEARCH_ITEMS_QUERY](state, action) {
        const {query} = action
        const query_ = state.get('query')
        const newQuery = Map(query)

        return state.set('query', query_.merge(newQuery))
    },
    [TRIAL_CHANGE_SELECT_STATUS_ITEMS](state, action) {
        const {status} = action

        return state.set('selectStatus', status)
    },
    [TRIAL_TOGGLE_EDITOR_FILTER_RANK](state, action) {
        return state
            .setIn(['rank', 'isOpen'], !state.getIn(['rank', 'isOpen']))
    },
    [TRIAL_OPEN_EDITOR_FILTER_RANK](state, action) {
        return state
            .setIn(['rank', 'isOpen'], true)
            .setIn(['rank', 'temp'], state.getIn(['rank', 'current']))
    },
    [TRIAL_CLOSE_EDITOR_FILTER_RANK](state, action) {
        return state.setIn(['rank', 'isOpen'], false)
    },
    [TRIAL_CHANGE_TEMP_FILTER_RANK](state, action) {
        const {data} = action
        const {from, to} = data
        const fromNumber = parseInt(from, 10)
        const toNumber = parseInt(to, 10)

        const fromValidated = (!fromNumber || fromNumber < 1 || Number.isNaN(fromNumber)) ? '' : fromNumber
        const toValidated = (!toNumber || Number.isNaN(toNumber)) ? '' : toNumber

        return state.setIn(['rank', 'temp'], fromJS({
            from: fromValidated ? fromValidated : '',
            to: toValidated ? toValidated : ''
        }))
    },
    [TRIAL_SUBMIT_FILTER_REVIEW](state, action) {
        const {data} = action

        return state
            .setIn(['review'], fromJS(data))
    },
    [TRIAL_SUBMIT_FILTER_SALE](state, action) {
        const {data} = action

        return state
            .setIn(['sale'], fromJS(data))
    },
    [TRIAL_SUBMIT_FILTER_VIEW](state, action) {
        const {data} = action

        return state
            .setIn(['view'], fromJS(data))
    },
    [TRIAL_SUBMIT_FILTER_RANK](state, action) {
        return state
            .setIn(['rank', 'current'], state.getIn(['rank', 'temp']))
            .setIn(['rank', 'isOpen'], false)
    },
    [TRIAL_OPEN_EDITOR_FILTER_TIME_AVAILABLE](state, action) {
        return state
            .setIn(['available', 'isOpen'], true)
            .setIn(['available', 'temp'], state.getIn(['available', 'current']))
    },
    [TRIAL_CLOSE_EDITOR_FILTER_TIME_AVAILABLE](state, action) {
        return state
            .setIn(['available', 'isOpen'], false)
    },
    [TRIAL_TOGGLE_EDITOR_FILTER_TIME_AVAILABLE](state, action) {
        return state
            .setIn(['available', 'isOpen'], !state.getIn(['available', 'isOpen']))
    },
    [TRIAL_SUBMIT_FILTER_TIME_AVAILABLE](state, action) {
        return state
            .setIn(['available', 'current'], state.getIn(['available', 'temp']))
            .setIn(['available', 'isOpen'], false)
    },
    [TRIAL_CHANGE_TEMP_FILTER_TIME_AVAILABLE](state, action) {
        const {data} = action
        const {from, to} = data

        return state
            .setIn(['available', 'temp'], fromJS({from, to}))
    },
    [TRIAL_CHANGE_FIELD_NAME_SORT_BY](state, action) {
        const {field} = action

        return state.setIn(['sortBy', 'field'], field)
    },
    [TRIAL_CHANGE_SELECT_TYPE_ITEM](state, action) {
        const {typeItem} = action

        return state.set('type', typeItem)
    },
    [TRIAL_CHANGE_SELECT_BRAND_TYPE_ITEM](state, action) {
        const {brandType} = action

        return state.set('brandType', brandType)
    },
    [TRIAL_TOGGLE_EDITOR_FILTER_PRICE](state, action) {
        return state
            .setIn(['price', 'isOpen'], !state.getIn(['price', 'isOpen']))
    },
    [TRIAL_OPEN_EDITOR_FILTER_PRICE](state, action) {
        return state
            .setIn(['price', 'isOpen'], true)
            .setIn(['price', 'temp'], state.getIn(['price', 'current']))
    },
    [TRIAL_CLOSE_EDITOR_FILTER_PRICE](state, action) {
        return state.setIn(['price', 'isOpen'], false)
    },
    [TRIAL_CHANGE_TEMP_FILTER_PRICE](state, action) {
        const {data} = action
        const {from, to} = data
        const fromNumber = parseInt(from, 10)
        const toNumber = parseInt(to, 10)

        const fromValidated = (!fromNumber || fromNumber < 0 || Number.isNaN(fromNumber)) ? 0 : fromNumber
        const toValidated = (!toNumber || Number.isNaN(toNumber)) ? 0 : toNumber

        return state.setIn(['price', 'temp'], fromJS({
            from: fromValidated ? fromValidated : 0,
            to: toValidated ? toValidated : 0
        }))
    },
    [TRIAL_SUBMIT_FILTER_PRICE](state, action) {
        return state
            .setIn(['price', 'current'], state.getIn(['price', 'temp']))
            .setIn(['price', 'isOpen'], false)
    },
    [TRIAL_SET_HIDDEN_FUZZY_POST](state, action) {
        return state.set('hiddenFuzzyPost', true)
    }
})

const initStateAnalytic = {
    analyticId: null,
    saving: false,
}

const analytics = createReducer(fromJS(initStateAnalytic), {
    [TRIAL_SAVE_QUERY_SEARCH_ITEM_REQUEST](state, action) {
        return state.set('saving', true)
    },
    [TRIAL_SAVE_QUERY_SEARCH_ITEM_SUCCESS](state, action) {
        const {data} = action.result
        const {createMyAnalytic} = data
        const {_id} = createMyAnalytic

        return state
            .set('saving', false)
            .set('analyticId', _id)
    },
    [TRIAL_SAVE_QUERY_SEARCH_ITEM_FAILURE](state, action) {
        return state.set('saving', false)
    },
    [TRIAL_SET_CURRENT_MY_ANALYTIC_ID](state, action) {
        const {analyticId} = action

        return state.set('analyticId', analyticId)
    },
    [FETCH_REMOVE_MY_ANALYTIC_SUCCESS](state, action) {
        return state.set('analyticId', null)
    }
})

export default combineReducers({
    items,
    pagination,
    showItem,
    meta,
    analytics
})
