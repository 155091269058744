export const PREPARE_CHECKOUT = 'checkout/PREPARE_CHECKOUT'
export const CHANGE_PAYMENT_METHOD = 'checkout/CHANGE_PAYMENT_METHOD'

export const FETCH_PLAN_DETAIL_REQUEST = 'checkout/FETCH_PLAN_DETAIL_REQUEST'
export const FETCH_PLAN_DETAIL_SUCCESS = 'checkout/FETCH_PLAN_DETAIL_SUCCESS'
export const FETCH_PLAN_DETAIL_FAILURE = 'checkout/FETCH_PLAN_DETAIL_FAILURE'

export const FETCH_AVAILABLE_GATEWAY_REQUEST = 'checkout/FETCH_AVAILABLE_GATEWAY_REQUEST'
export const FETCH_AVAILABLE_GATEWAY_SUCCESS = 'checkout/FETCH_AVAILABLE_GATEWAY_SUCCESS'
export const FETCH_AVAILABLE_GATEWAY_FAILURE = 'checkout/FETCH_AVAILABLE_GATEWAY_FAILURE'

export const FETCH_STRIPE_USER_REQUEST = 'checkout/FETCH_STRIPE_USER_REQUEST'
export const FETCH_STRIPE_USER_SUCCESS = 'checkout/FETCH_STRIPE_USER_SUCCESS'
export const FETCH_STRIPE_USER_FAILURE = 'checkout/FETCH_STRIPE_USER_FAILURE'

export const STRIPE_ADD_CARD_REQUEST = 'checkout/STRIPE_ADD_CARD_REQUEST'
export const STRIPE_ADD_CARD_SUCCESS = 'checkout/STRIPE_ADD_CARD_SUCCESS'
export const STRIPE_ADD_CARD_FAILURE = 'checkout/STRIPE_ADD_CARD_FAILURE'

export const STRIPE_REMOVE_CARD_REQUEST = 'checkout/STRIPE_REMOVE_CARD_REQUEST'
export const STRIPE_REMOVE_CARD_SUCCESS = 'checkout/STRIPE_REMOVE_CARD_SUCCESS'
export const STRIPE_REMOVE_CARD_FAILURE = 'checkout/STRIPE_REMOVE_CARD_FAILURE'

export const STRIPE_CHECKOUT_REQUEST = 'checkout/STRIPE_CHECKOUT_REQUEST'
export const STRIPE_CHECKOUT_SUCCESS = 'checkout/STRIPE_CHECKOUT_SUCCESS'
export const STRIPE_CHECKOUT_FAILURE = 'checkout/STRIPE_CHECKOUT_FAILURE'

export const PAYPAL_CHECKOUT_REQUEST = 'checkout/PAYPAL_CHECKOUT_REQUEST'
export const PAYPAL_CHECKOUT_SUCCESS = 'checkout/PAYPAL_CHECKOUT_SUCCESS'
export const PAYPAL_CHECKOUT_FAILURE = 'checkout/PAYPAL_CHECKOUT_FAILURE'

export const FREE_CHECKOUT_REQUEST = 'checkout/FREE_CHECKOUT_REQUEST'
export const FREE_CHECKOUT_SUCCESS = 'checkout/FREE_CHECKOUT_SUCCESS'
export const FREE_CHECKOUT_FAILURE = 'checkout/FREE_CHECKOUT_FAILURE'

export const FETCH_COUPON_AVAILABLE_REQUEST = 'checkout/FETCH_COUPON_AVAILABLE_REQUEST'
export const FETCH_COUPON_AVAILABLE_SUCCESS = 'checkout/FETCH_COUPON_AVAILABLE_SUCCESS'
export const FETCH_COUPON_AVAILABLE_FAILURE = 'checkout/FETCH_COUPON_AVAILABLE_FAILURE'

export const FETCH_TAPFILIATE_COUPON_CODE_REQUEST = 'checkout/FETCH_TAPFILIATE_COUPON_CODE_REQUEST'
export const FETCH_TAPFILIATE_COUPON_CODE_SUCCESS = 'checkout/FETCH_TAPFILIATE_COUPON_CODE_SUCCESS'
export const FETCH_TAPFILIATE_COUPON_CODE_FAILURE = 'checkout/FETCH_TAPFILIATE_COUPON_CODE_FAILURE'

export const APPLY_COUPON_CODE_REQUEST = 'checkout/APPLY_COUPON_CODE_REQUEST'
export const APPLY_COUPON_CODE_SUCCESS = 'checkout/APPLY_COUPON_CODE_SUCCESS'
export const APPLY_COUPON_CODE_FAILURE = 'checkout/APPLY_COUPON_CODE_FAILURE'
