import getEnv from "./common/getEnv"

export const getAppURL = (path = '') => {
    return getEnv('baseUrl') + path
}

export const parseHTMLToText = (html = '') => {
    const tmp = document.createElement("DIV")
    tmp.innerHTML = html

    return tmp.textContent || tmp.innerText || ""
}
