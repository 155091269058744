import {createReducer} from "redux-create-reducer"
import {combineReducers} from "redux-immutable"
import {fromJS, Map} from "immutable"

import {
    CHANGE_FIELD_NAME_SORT_BY_SHOPS,
    CHANGE_PAGINATION_SHOPS_CURRENT_PAGE,
    CHANGE_SELECT_STATUS_SHOPS,
    FETCH_LIST_SHOPS_FAILURE,
    FETCH_LIST_SHOPS_SUCCESS,
    FETCH_LIST_SHOPS_REQUEST,
    INIT_SETUP_META,
    SUBMIT_FILTER_REVIEW_SHOPS,
    SUBMIT_FILTER_SALE_SHOPS,
    OPEN_EDITOR_FILTER_TIME_AVAILABLE_SHOP,
    CLOSE_EDITOR_FILTER_TIME_AVAILABLE_SHOP,
    TOGGLE_EDITOR_FILTER_TIME_AVAILABLE_SHOP,
    SUBMIT_FILTER_TIME_AVAILABLE_SHOP,
    CHANGE_TEMP_FILTER_TIME_AVAILABLE_SHOP
} from "./actionTypes"
import {AUTH_LOGIN_SUCCESS} from "../../constants/ActionTypes"

const initShopsState = {
    shops: {},
    loading: false,
    error: ''
}

const shops = createReducer(fromJS(initShopsState), {
    [FETCH_LIST_SHOPS_REQUEST](state) {
        return state
            .set('loading', true)
            .set('error', '')
    },
    [FETCH_LIST_SHOPS_FAILURE](state, action) {
        const {error} = action
        const message = error.message || error

        return state
            .set('loading', false)
            .set('error', message)
            .set('shops', fromJS([]))
    },
    [FETCH_LIST_SHOPS_SUCCESS](state, action) {
        const {result} = action
        const {data, success} = result

        if (!success) {
            return state.set('loading', false)
        }

        return state
            .set('loading', false)
            .set('error', '')
            .set('shops', fromJS(data.docs))
    }
})

const pagination = createReducer(
    Map({
        page: 1,
        totalPage: 0,
        perPage: 100,
        total: 0,
        query: {}
    }),
    {
        [INIT_SETUP_META](state, action) {
            return state.set('page', 1)
        },
        [FETCH_LIST_SHOPS_SUCCESS](state, action) {
            const {result} = action
            const {data, success} = result

            if (!success) {
                return state
            }

            return state
                .set('totalPage', data.pages)
                .set('total', data.total)
        },
        [CHANGE_PAGINATION_SHOPS_CURRENT_PAGE](state, action) {
            const {page} = action

            return state.set('page', page)
        },
    }
)

const initMetaState = {
    filter: {},
    status: 'all',
    review: {
        from: '',
        to: ''
    },
    sale: {
        from: '',
        to: ''
    },
    sortBy: {
        field: 'review',
    },
    available: {
        isOpen: false,
        current: {
            from: '',
            to: null
        },
        temp: {
            from: null,
            to: null
        }
    },
}

const meta = createReducer(fromJS(initMetaState), {
    [INIT_SETUP_META](state) {
        const init = fromJS(initMetaState)
        return state.merge(init);
    },
    [AUTH_LOGIN_SUCCESS](state, action) {
        const {data} = action
        const {profile} = data
        const {settings} = profile

        if (settings) {
            const {layoutListing} = settings

            if (layoutListing) {
                return state.set('layout', layoutListing)
            }
        }

        return state
    },
    [CHANGE_SELECT_STATUS_SHOPS](state, action) {
        const {status} = action

        return state.set('status', status)
    },
    [SUBMIT_FILTER_REVIEW_SHOPS](state, action) {
        const {data} = action

        return state
            .setIn(['review'], fromJS(data))
    },
    [SUBMIT_FILTER_SALE_SHOPS](state, action) {
        const {data} = action

        return state
            .setIn(['sale'], fromJS(data))
    },
    [CHANGE_FIELD_NAME_SORT_BY_SHOPS](state, action) {
        const {field} = action

        return state.setIn(['sortBy', 'field'], field)
    },
    [OPEN_EDITOR_FILTER_TIME_AVAILABLE_SHOP](state, action) {
        return state
            .setIn(['available', 'isOpen'], true)
            .setIn(['available', 'temp'], state.getIn(['available', 'current']))
    },
    [CLOSE_EDITOR_FILTER_TIME_AVAILABLE_SHOP](state, action) {
        return state
            .setIn(['available', 'isOpen'], false)
    },
    [TOGGLE_EDITOR_FILTER_TIME_AVAILABLE_SHOP](state, action) {
        return state
            .setIn(['available', 'isOpen'], !state.getIn(['available', 'isOpen']))
    },
    [SUBMIT_FILTER_TIME_AVAILABLE_SHOP](state, action) {
        return state
            .setIn(['available', 'current'], state.getIn(['available', 'temp']))
            .setIn(['available', 'isOpen'], false)
    },
    [CHANGE_TEMP_FILTER_TIME_AVAILABLE_SHOP](state, action) {
        const {data} = action
        const {from, to} = data

        return state
            .setIn(['available', 'temp'], fromJS({from, to}))
    }
})

export default combineReducers({
    shops,
    pagination,
    meta
})
