import React, {Component} from "react"
import {connect} from "react-redux"
import HomePage from "./HomePage"
import {isAuthenticated} from "../../../selectors/AuthSelectors"

class HomePageContainer extends Component {
    render() {
        const {props} = this

        return (
            <HomePage {...props}/>
        )
    }

    componentDidMount() {
        document.title = 'Home page'
    }
}

const mapStateToProps = (state, props) => ({
    isAuthenticated: isAuthenticated(state),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer)
