export const FETCH_STATISTIC_RANK_RANGES_REQUEST = "FETCH_STATISTIC_RANK_RANGES_REQUEST"
export const FETCH_STATISTIC_RANK_RANGES_SUCCESS = "FETCH_STATISTIC_RANK_RANGES_SUCCESS"
export const FETCH_STATISTIC_RANK_RANGES_FAILURE = "FETCH_STATISTIC_RANK_RANGES_FAILURE"

export const FETCH_STATISTIC_ITEM_TYPES_REQUEST = "FETCH_STATISTIC_ITEM_TYPES_REQUEST"
export const FETCH_STATISTIC_ITEM_TYPES_SUCCESS = "FETCH_STATISTIC_ITEM_TYPES_SUCCESS"
export const FETCH_STATISTIC_ITEM_TYPES_FAILURE = "FETCH_STATISTIC_ITEM_TYPES_FAILURE"

export const FETCH_ITEMS_TOP_RISING_REQUEST = "FETCH_ITEMS_TOP_RISING_REQUEST"
export const FETCH_ITEMS_TOP_RISING_SUCCESS = "FETCH_ITEMS_TOP_RISING_SUCCESS"
export const FETCH_ITEMS_TOP_RISING_FAILURE = "FETCH_ITEMS_TOP_RISING_FAILURE"

export const FETCH_ITEMS_TOP_RANK_REQUEST = "FETCH_ITEMS_TOP_RANK_REQUEST"
export const FETCH_ITEMS_TOP_RANK_SUCCESS = "FETCH_ITEMS_TOP_RANK_SUCCESS"
export const FETCH_ITEMS_TOP_RANK_FAILURE = "FETCH_ITEMS_TOP_RANK_FAILURE"

export const FETCH_ITEMS_RANDOM_REQUEST = "FETCH_ITEMS_RANDOM_REQUEST"
export const FETCH_ITEMS_RANDOM_SUCCESS = "FETCH_ITEMS_RANDOM_SUCCESS"
export const FETCH_ITEMS_RANDOM_FAILURE = "FETCH_ITEMS_RANDOM_FAILURE"
