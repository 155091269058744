export const TRAIL_FETCH_LIST_ITEMS_REQUEST = "TRAIL_FETCH_LIST_ITEMS_REQUEST"
export const TRAIL_FETCH_LIST_ITEMS_SUCCESS = "TRAIL_FETCH_LIST_ITEMS_SUCCESS"
export const TRAIL_FETCH_LIST_ITEMS_FAILURE = "TRAIL_FETCH_LIST_ITEMS_FAILURE"

export const TRAIL_FETCH_ITEM_DETAIL_REQUEST = "TRAIL_FETCH_ITEM_DETAIL_REQUEST"
export const TRAIL_FETCH_ITEM_DETAIL_SUCCESS = "TRAIL_FETCH_ITEM_DETAIL_SUCCESS"
export const TRAIL_FETCH_ITEM_DETAIL_FAILURE = "TRAIL_FETCH_ITEM_DETAIL_FAILURE"

export const TRAIL_FETCH_TOTAL_ITEMS_REQUEST = "TRAIL_FETCH_TOTAL_ITEMS_REQUEST"
export const TRAIL_FETCH_TOTAL_ITEMS_SUCCESS = "TRAIL_FETCH_TOTAL_ITEMS_SUCCESS"
export const TRAIL_FETCH_TOTAL_ITEMS_FAILURE = "TRAIL_FETCH_TOTAL_ITEMS_FAILURE"

export const TRIAL_TOGGLE_LIKE_ITEM_REQUEST = "TRIAL_TOGGLE_LIKE_ITEM_REQUEST"
export const TRIAL_TOGGLE_LIKE_ITEM_SUCCESS = "TRIAL_TOGGLE_LIKE_ITEM_SUCCESS"
export const TRIAL_TOGGLE_LIKE_ITEM_FAILURE = "TRIAL_TOGGLE_LIKE_ITEM_FAILURE"

export const TRIAL_CHANGE_PAGINATION_ITEMS_CURRENT_PAGE = "TRIAL_CHANGE_PAGINATION_ITEMS_CURRENT_PAGE"
export const TRIAL_CHANGE_PAGINATION_ITEMS_PER_PAGE = "TRIAL_CHANGE_PAGINATION_ITEMS_PER_PAGE"
export const TRIAL_CHANGE_SEARCH_ITEMS_QUERY = "TRIAL_CHANGE_SEARCH_ITEMS_QUERY"
export const TRIAL_CHANGE_SELECT_STATUS_ITEMS = "TRIAL_CHANGE_SELECT_STATUS_ITEMS"
export const TRIAL_CHANGE_FIELD_NAME_SORT_BY = "TRIAL_CHANGE_FIELD_NAME_SORT_BY"
export const TRIAL_CHANGE_SELECT_TYPE_ITEM = "TRIAL_CHANGE_SELECT_TYPE_ITEM"
export const TRIAL_CHANGE_SELECT_BRAND_TYPE_ITEM = "TRIAL_CHANGE_SELECT_BRAND_TYPE_ITEM"

export const TRIAL_SHOW_ITEM_DETAIL = "TRIAL_SHOW_ITEM_DETAIL"
export const TRIAL_CLOSE_MODAL_ITEM_DETAIL = "TRIAL_CLOSE_MODAL_ITEM_DETAIL"

export const TRIAL_TOGGLE_EDITOR_FILTER_RANK = "TRIAL_TOGGLE_EDITOR_FILTER_RANK"
export const TRIAL_OPEN_EDITOR_FILTER_RANK = "TRIAL_OPEN_EDITOR_FILTER_RANK"
export const TRIAL_CLOSE_EDITOR_FILTER_RANK = "TRIAL_CLOSE_EDITOR_FILTER_RANK"
export const TRIAL_CHANGE_TEMP_FILTER_RANK = "TRIAL_CHANGE_TEMP_FILTER_RANK"
export const TRIAL_SUBMIT_FILTER_RANK = "TRIAL_SUBMIT_FILTER_RANK"

export const TRIAL_TOGGLE_EDITOR_FILTER_PRICE = "TRIAL_TOGGLE_EDITOR_FILTER_PRICE"
export const TRIAL_OPEN_EDITOR_FILTER_PRICE = "TRIAL_OPEN_EDITOR_FILTER_PRICE"
export const TRIAL_CLOSE_EDITOR_FILTER_PRICE = "TRIAL_CLOSE_EDITOR_FILTER_PRICE"
export const TRIAL_CHANGE_TEMP_FILTER_PRICE = "TRIAL_CHANGE_TEMP_FILTER_PRICE"
export const TRIAL_SUBMIT_FILTER_PRICE = "TRIAL_SUBMIT_FILTER_PRICE"

export const TRIAL_TOGGLE_EDITOR_FILTER_TIME_AVAILABLE = "TRIAL_TOGGLE_EDITOR_FILTER_TIME_AVAILABLE"
export const TRIAL_OPEN_EDITOR_FILTER_TIME_AVAILABLE = "TRIAL_OPEN_EDITOR_FILTER_TIME_AVAILABLE"
export const TRIAL_CLOSE_EDITOR_FILTER_TIME_AVAILABLE = "TRIAL_CLOSE_EDITOR_FILTER_TIME_AVAILABLE"
export const TRIAL_CHANGE_TEMP_FILTER_TIME_AVAILABLE = "TRIAL_CHANGE_TEMP_FILTER_TIME_AVAILABLE"
export const TRIAL_SUBMIT_FILTER_TIME_AVAILABLE = "TRIAL_SUBMIT_FILTER_TIME_AVAILABLE"

export const TRIAL_SUBMIT_FILTER_REVIEW = "TRIAL_SUBMIT_FILTER_REVIEW"
export const TRIAL_SUBMIT_FILTER_SALE = "TRIAL_SUBMIT_FILTER_SALE"
export const TRIAL_SUBMIT_FILTER_VIEW = "TRIAL_SUBMIT_FILTER_VIEW"

export const TRIAL_INIT_SETUP_META = "TRIAL_INIT_SETUP_META"

export const TRIAL_SWITCH_LAYOUT_PRODUCTS = "TRIAL_SWITCH_LAYOUT_PRODUCTS"

export const TRIAL_SAVE_QUERY_SEARCH_ITEM_REQUEST = "TRIAL_SAVE_QUERY_SEARCH_ITEM_REQUEST"
export const TRIAL_SAVE_QUERY_SEARCH_ITEM_SUCCESS = "TRIAL_SAVE_QUERY_SEARCH_ITEM_SUCCESS"
export const TRIAL_SAVE_QUERY_SEARCH_ITEM_FAILURE = "TRIAL_SAVE_QUERY_SEARCH_ITEM_FAILURE"

export const TRIAL_CHANGE_QUERY = "TRIAL_CHANGE_QUERY"

export const TRIAL_SET_CURRENT_MY_ANALYTIC_ID = "TRIAL_SET_CURRENT_MY_ANALYTIC_ID"

export const TRIAL_SET_HIDDEN_FUZZY_POST = "TRIAL_SET_HIDDEN_FUZZY_POST"
