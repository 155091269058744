export const FETCH_LIST_SHOPS_REQUEST = "FETCH_LIST_SHOPS_REQUEST"
export const FETCH_LIST_SHOPS_SUCCESS = "FETCH_LIST_SHOPS_SUCCESS"
export const FETCH_LIST_SHOPS_FAILURE = "FETCH_LIST_SHOPS_FAILURE"

export const CHANGE_PAGINATION_SHOPS_CURRENT_PAGE = "CHANGE_PAGINATION_SHOPS_CURRENT_PAGE"
export const CHANGE_SELECT_STATUS_SHOPS = "CHANGE_SELECT_STATUS_SHOPS"
export const CHANGE_FIELD_NAME_SORT_BY_SHOPS = "CHANGE_FIELD_NAME_SORT_BY_SHOPS"

export const SUBMIT_FILTER_REVIEW_SHOPS = "SUBMIT_FILTER_REVIEW_SHOPS"
export const SUBMIT_FILTER_SALE_SHOPS = "SUBMIT_FILTER_SALE_SHOPS"
export const SUBMIT_FILTER_VIEW = "SUBMIT_FILTER_VIEW"

export const INIT_SETUP_META = "INIT_SETUP_META"

export const CHANGE_TEMP_FILTER_TIME_AVAILABLE_SHOP = "CHANGE_TEMP_FILTER_TIME_AVAILABLE_SHOP"
export const OPEN_EDITOR_FILTER_TIME_AVAILABLE_SHOP = "OPEN_EDITOR_FILTER_TIME_AVAILABLE_SHOP"
export const CLOSE_EDITOR_FILTER_TIME_AVAILABLE_SHOP = "CLOSE_EDITOR_FILTER_TIME_AVAILABLE_SHOP"
export const SUBMIT_FILTER_TIME_AVAILABLE_SHOP = "SUBMIT_FILTER_TIME_AVAILABLE_SHOP"
export const TOGGLE_EDITOR_FILTER_TIME_AVAILABLE_SHOP = "TOGGLE_EDITOR_FILTER_TIME_AVAILABLE_SHOP"

