export const FETCH_LIST_ITEMS_REQUEST = "FETCH_LIST_ITEMS_REQUEST"
export const FETCH_LIST_ITEMS_SUCCESS = "FETCH_LIST_ITEMS_SUCCESS"
export const FETCH_LIST_ITEMS_FAILURE = "FETCH_LIST_ITEMS_FAILURE"

export const FETCH_ITEM_DETAIL_REQUEST = "search/FETCH_ITEM_DETAIL_REQUEST"
export const FETCH_ITEM_DETAIL_SUCCESS = "search/FETCH_ITEM_DETAIL_SUCCESS"
export const FETCH_ITEM_DETAIL_FAILURE = "search/FETCH_ITEM_DETAIL_FAILURE"

export const FETCH_TOTAL_ITEMS_REQUEST = "FETCH_TOTAL_ITEMS_REQUEST"
export const FETCH_TOTAL_ITEMS_SUCCESS = "FETCH_TOTAL_ITEMS_SUCCESS"
export const FETCH_TOTAL_ITEMS_FAILURE = "FETCH_TOTAL_ITEMS_FAILURE"

export const TOGGLE_LIKE_ITEM_REQUEST = "TOGGLE_LIKE_ITEM_REQUEST"
export const TOGGLE_LIKE_ITEM_SUCCESS = "TOGGLE_LIKE_ITEM_SUCCESS"
export const TOGGLE_LIKE_ITEM_FAILURE = "TOGGLE_LIKE_ITEM_FAILURE"

export const CHANGE_PAGINATION_ITEMS_CURRENT_PAGE = "CHANGE_PAGINATION_ITEMS_CURRENT_PAGE"
export const CHANGE_PAGINATION_ITEMS_PER_PAGE = "CHANGE_PAGINATION_ITEMS_PER_PAGE"
export const CHANGE_SEARCH_ITEMS_QUERY = "CHANGE_SEARCH_ITEMS_QUERY"
export const CHANGE_SELECT_STATUS_ITEMS = "CHANGE_SELECT_STATUS_ITEMS"
export const CHANGE_FIELD_NAME_SORT_BY = "CHANGE_FIELD_NAME_SORT_BY"
export const CHANGE_SELECT_TYPE_ITEM = "CHANGE_SELECT_TYPE_ITEM"
export const CHANGE_SELECT_BRAND_TYPE_ITEM = "CHANGE_SELECT_BRAND_TYPE_ITEM"

export const SHOW_ITEM_DETAIL = "SHOW_ITEM_DETAIL"
export const CLOSE_MODAL_ITEM_DETAIL = "CLOSE_MODAL_ITEM_DETAIL"

export const TOGGLE_EDITOR_FILTER_RANK = "TOGGLE_EDITOR_FILTER_RANK"
export const OPEN_EDITOR_FILTER_RANK = "OPEN_EDITOR_FILTER_RANK"
export const CLOSE_EDITOR_FILTER_RANK = "CLOSE_EDITOR_FILTER_RANK"
export const CHANGE_TEMP_FILTER_RANK = "CHANGE_TEMP_FILTER_RANK"
export const SUBMIT_FILTER_RANK = "SUBMIT_FILTER_RANK"

export const TOGGLE_EDITOR_FILTER_PRICE = "TOGGLE_EDITOR_FILTER_PRICE"
export const OPEN_EDITOR_FILTER_PRICE = "OPEN_EDITOR_FILTER_PRICE"
export const CLOSE_EDITOR_FILTER_PRICE = "CLOSE_EDITOR_FILTER_PRICE"
export const CHANGE_TEMP_FILTER_PRICE = "CHANGE_TEMP_FILTER_PRICE"
export const SUBMIT_FILTER_PRICE = "SUBMIT_FILTER_PRICE"

export const TOGGLE_EDITOR_FILTER_TIME_AVAILABLE = "TOGGLE_EDITOR_FILTER_TIME_AVAILABLE"
export const OPEN_EDITOR_FILTER_TIME_AVAILABLE = "OPEN_EDITOR_FILTER_TIME_AVAILABLE"
export const CLOSE_EDITOR_FILTER_TIME_AVAILABLE = "CLOSE_EDITOR_FILTER_TIME_AVAILABLE"
export const CHANGE_TEMP_FILTER_TIME_AVAILABLE = "CHANGE_TEMP_FILTER_TIME_AVAILABLE"
export const SUBMIT_FILTER_TIME_AVAILABLE = "SUBMIT_FILTER_TIME_AVAILABLE"

export const SUBMIT_FILTER_REVIEW = "SUBMIT_FILTER_REVIEW"
export const SUBMIT_FILTER_SALE = "SUBMIT_FILTER_SALE"
export const SUBMIT_FILTER_VIEW = "SUBMIT_FILTER_VIEW"

export const INIT_SETUP_META = "INIT_SETUP_META"

export const SWITCH_LAYOUT_PRODUCTS = "SWITCH_LAYOUT_PRODUCTS"

export const SAVE_QUERY_SEARCH_ITEM_REQUEST = "SAVE_QUERY_SEARCH_ITEM_REQUEST"
export const SAVE_QUERY_SEARCH_ITEM_SUCCESS = "SAVE_QUERY_SEARCH_ITEM_SUCCESS"
export const SAVE_QUERY_SEARCH_ITEM_FAILURE = "SAVE_QUERY_SEARCH_ITEM_FAILURE"

export const CHANGE_QUERY = "search/CHANGE_QUERY"

export const SET_CURRENT_MY_ANALYTIC_ID = "SET_CURRENT_MY_ANALYTIC_ID"
