export const FETCH_KEYWORD_OPTIONS_REQUEST = "FETCH_KEYWORD_OPTIONS_REQUEST"
export const FETCH_KEYWORD_OPTIONS_SUCCESS = "FETCH_KEYWORD_OPTIONS_SUCCESS"
export const FETCH_KEYWORD_OPTIONS_FAILURE = "FETCH_KEYWORD_OPTIONS_FAILURE"

export const KEYWORD_CHANGE_SELECT_OPTIONS = "KEYWORD_CHANGE_SELECT_OPTIONS"

export const FETCH_LIST_KEYWORDS_REQUEST = "FETCH_LIST_KEYWORDS_REQUEST"
export const FETCH_LIST_KEYWORDS_SUCCESS = "FETCH_LIST_KEYWORDS_SUCCESS"
export const FETCH_LIST_KEYWORDS_FAILURE = "FETCH_LIST_KEYWORDS_FAILURE"

export const KEYWORD_CHANGE_PAGINATION_NUMBER = "KEYWORD_CHANGE_PAGINATION_NUMBER"
export const KEYWORD_CHANGE_PAGINATION_PER_PAGE = "KEYWORD_CHANGE_PAGINATION_PER_PAGE"
