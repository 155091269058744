const config = {
    domain: {
        $default: 'localhost',
        staging: 'spyetsy.com',
        production: 'spyetsy.com'
    },
    baseUrl: {
        $default: 'https://localhost:3000',
        staging: 'https://dev-merch.spyetsy.com',
        production: 'https://app.spyetsy.com',
    },
    baseFileUrl: {
        $default: 'https://files.spyetsy.com',
        staging: 'https://dev-merch.spyetsy.com/painter',
        production: 'https://files.spyetsy.com',
    },
    baseAPIUrl: {
        $default: 'http://localhost:7788',
        staging: '/api',
        production: '/api',
    },
    hostAPI: {
        $default: 'https://prod.spyetsy.com',
        staging: 'https://dev-merch.spyetsy.com',
        production: 'https://prod.spyetsy.com',
    },
    realtimeServices: {
        $default: 'https://prod-r.spyetsy.com',
        staging: 'https://dev-merch.spyetsy.com/realtime',
        production: 'https://prod-r.spyetsy.com'
    },
    baseAdminUrl: {
        $default: 'http://localhost:3001',
        staging: 'https://dev-admin.spyetsy.com',
        production: 'https://admin.spyetsy.com',
    },
    baseAdminAPI: {
        // $default: 'https://prod.spyetsy.com/admin',
        $default: 'http://localhost:1238',
        staging: 'https://dev-admin.spyetsy.com',
        production: 'https://prod-admin.spyetsy.com',
    },
    basePaymentAPI: {
        // $default: 'https://prod.spyetsy.com/payment',
        $default: 'https://dev-merch.spyetsy.com/payment',
        staging: 'https://dev-merch.spyetsy.com/payment',
        production: 'https://prod.spyetsy.com/payment',
    },
    baseTrackingAPI: {
        $default: 'https://prod.spyetsy.com/tracking',
        staging: 'https://dev-merch.spyetsy.com/tracking',
        production: 'https://prod.spyetsy.com/tracking',
    },
    baseAuthAPI: {
        // $default: 'https://prod.spyetsy.com/authentication',
        $default: 'http://localhost:5566',
        staging: 'https://dev-merch.spyetsy.com/auth',
        production: 'https://prod.spyetsy.com/authentication',
    },
    baseAffiliateAPI: {
        $default: 'https://prod.spyetsy.com/affiliate',
        staging: 'https://dev.spyetsy.com/affiliate',
        production: 'https://prod.spyetsy.com/affiliate',
    },
    filesUrl: {
        $default: 'https://dev-files.spyamz.com',
        staging: 'https://dev-files.spyamz.com',
        production: 'https://files.spyamz.com',
    },
    stripePublicKey: {
        $default: 'pk_test_QyTOlQCBIM7vIoUvDGWRbruM',
        staging: 'pk_test_QyTOlQCBIM7vIoUvDGWRbruM',
        production: 'pk_live_RQxnRahQebnthVQIju5yFnAn',
    }
}

const _getEnvironment = () => {
    return process.env.REACT_APP_ENV || 'development'
}

export default (key, defaultValue = null) => {
    if (!config.hasOwnProperty(key)) {
        return defaultValue
    }

    const env = _getEnvironment()
    const _dataConfig = config[key]

    return _dataConfig[env] ? _dataConfig[env] : _dataConfig['$default'] || defaultValue
}

