export const FETCH_LIST_FAVORITE_ITEMS_REQUEST = "favorite/FETCH_LIST_FAVORITE_ITEMS_REQUEST"
export const FETCH_LIST_FAVORITE_ITEMS_SUCCESS = "favorite/FETCH_LIST_FAVORITE_ITEMS_SUCCESS"
export const FETCH_LIST_FAVORITE_ITEMS_FAILURE = "favorite/FETCH_LIST_FAVORITE_ITEMS_FAILURE"

export const FETCH_ITEM_DETAIL_REQUEST = "favorite/FETCH_ITEM_DETAIL_REQUEST"
export const FETCH_ITEM_DETAIL_SUCCESS = "favorite/FETCH_ITEM_DETAIL_SUCCESS"
export const FETCH_ITEM_DETAIL_FAILURE = "favorite/FETCH_ITEM_DETAIL_FAILURE"

export const CHANGE_PAGINATION_ITEMS_CURRENT_PAGE = "favorite/CHANGE_PAGINATION_ITEMS_CURRENT_PAGE"
export const CHANGE_PAGINATION_ITEMS_PER_PAGE = "favorite/CHANGE_PAGINATION_ITEMS_PER_PAGE"

export const SHOW_ITEM_DETAIL = "favorite/SHOW_ITEM_DETAIL"
export const CLOSE_MODAL_ITEM_DETAIL = "favorite/CLOSE_MODAL_ITEM_DETAIL"
