import {NAME} from "./constants"

export const FETCH_LIST_TRADEMARKS_REQUEST = NAME + "/FETCH_LIST_TRADEMARKS_REQUEST"
export const FETCH_LIST_TRADEMARKS_SUCCESS = NAME + "/FETCH_LIST_TRADEMARKS_SUCCESS"
export const FETCH_LIST_TRADEMARKS_FAILURE = NAME + "/FETCH_LIST_TRADEMARKS_FAILURE"

export const FETCH_TRADEMARK_DETAIL_REQUEST = NAME + "/FETCH_TRADEMARK_DETAIL_REQUEST"
export const FETCH_TRADEMARK_DETAIL_SUCCESS = NAME + "/FETCH_TRADEMARK_DETAIL_SUCCESS"
export const FETCH_TRADEMARK_DETAIL_FAILURE = NAME + "/FETCH_TRADEMARK_DETAIL_FAILURE"

export const CREATE_NEW_TRADEMARK_REQUEST = NAME + "/CREATE_NEW_TRADEMARK_REQUEST"
export const CREATE_NEW_TRADEMARK_SUCCESS = NAME + "/CREATE_NEW_TRADEMARK_SUCCESS"
export const CREATE_NEW_TRADEMARK_FAILURE = NAME + "/CREATE_NEW_TRADEMARK_FAILURE"

export const DELETE_TRADEMARK_REQUEST = NAME + "/DELETE_TRADEMARK_REQUEST"
export const DELETE_TRADEMARK_SUCCESS = NAME + "/DELETE_TRADEMARK_SUCCESS"
export const DELETE_TRADEMARK_FAILURE = NAME + "/DELETE_TRADEMARK_FAILURE"

export const REFRESH_TRADEMARK_REQUEST = NAME + "/REFRESH_TRADEMARK_REQUEST"
export const REFRESH_TRADEMARK_SUCCESS = NAME + "/REFRESH_TRADEMARK_SUCCESS"
export const REFRESH_TRADEMARK_FAILURE = NAME + "/REFRESH_TRADEMARK_FAILURE"

export const FETCH_STATISTIC_REQUEST = NAME + "/FETCH_STATISTIC_REQUEST"
export const FETCH_STATISTIC_SUCCESS = NAME + "/FETCH_STATISTIC_SUCCESS"
export const FETCH_STATISTIC_FAILURE = NAME + "/FETCH_STATISTIC_FAILURE"

export const FETCH_TOTAL_WARNINGS_REQUEST = NAME + "/FETCH_TOTAL_WARNINGS_REQUEST"
export const FETCH_TOTAL_WARNINGS_SUCCESS = NAME + "/FETCH_TOTAL_WARNINGS_SUCCESS"
export const FETCH_TOTAL_WARNINGS_FAILURE = NAME + "/FETCH_TOTAL_WARNINGS_FAILURE"

export const MARK_READ_TRADEMARK_REQUEST = NAME + "/MARK_READ_TRADEMARK_REQUEST"
export const MARK_READ_TRADEMARK_SUCCESS = NAME + "/MARK_READ_TRADEMARK_SUCCESS"
export const MARK_READ_TRADEMARK_FAILURE = NAME + "/MARK_READ_TRADEMARK_FAILURE"

export const CHANGE_INPUT_CREATE_TRADEMARK = NAME + "/CHANGE_INPUT_CREATE_TRADEMARK"
export const CHANGE_CURRENT_PAGE = NAME + "/CHANGE_CURRENT_PAGE"
export const CHANGE_SORT_BY = NAME + "/CHANGE_SORT_BY"

export const SHOW_TRADEMARK_RESULTS = NAME + "/SHOW_TRADEMARK_RESULTS"
export const HIDE_TRADEMARK_RESULTS = NAME + "/HIDE_TRADEMARK_RESULTS"
